<template>
  <v-container>
    <v-card max-width="800px" class="mx-auto pa-1" color="transparent" outlined>
      <h3 class="mb-3">알림 데이터 수정 및 테스트</h3>

      <div class="btn_container">
        <v-btn style="width:100%;" @click="checkAll()">SMS Alarm Check RUN All</v-btn>

        <div class="flex_container mt-2">
          <v-btn style="width:33%" @click="checkNoData()">No Data RUN</v-btn>
          <v-btn style="width:33%" class="ml-1 mr-1" @click="checkDiff()">Diff RUN</v-btn>
          <v-btn style="width:33%" @click="checkRange()">Range RUN</v-btn>
        </div>
      </div>

      <div class="input_container mt-3">
        <div class="section">
          <div class="flex_container">
            <div class="text_title">SMS 수신 대기 해제</div>
            <v-text-field dense
            style="max-width:230px !important;"
            v-model="phone"
            />
            <v-btn class="option_btn" @click="smsWait()"> 해제 </v-btn>
          </div>

          <div class="flex_container">
            <div class="text_title"></div>
            <div class="caption"> 전화번호 또는 ALL (전화번호는 '-'는 불포함)</div>
          </div>
        </div>

        <div class="section">
          <div class="flex_container">
            <div class="text_title">돈방 가상데이터 생성</div>

            <div>
              <div class="flex_container">
                <div class="text_subtitle">Room Code</div>
                <v-text-field dense
                v-model="rc"
                />
              </div>

              <div class="flex_container">
                <div class="text_subtitle">온도</div>
                <v-text-field dense
                v-model="value"
                />
              </div>

              <div class="flex_container">
                <div class="text_subtitle">생성시간</div>
                <v-text-field dense
                v-model="sec"
                />
              </div>
            </div>

            <v-btn class="option_btn" @click="setData()">생성</v-btn>
          </div>

          <div class="flex_container">
            <div class="text_title"></div>
            <div class="caption"> 사용예시<br/>
              [돈방코드] Onespring 알림시험 : R24081, 알림온도 기준 : R24082,<br/>
              [생성시간(second)] 0 이면 지금, -3600은 한시간 전, 300이면 5분후를 의미함</div>
          </div>
        </div>

        <div class="section">
          <div class="flex_container">
            <div class="text_title">가상 돈방 데이터 삭제</div>

            <div class="text_subtitle"> Room Code</div>
            <v-text-field dense
            v-model="rc2"
            />
            <v-btn class="option_btn ml" @click="deleteData()">삭제</v-btn>
          </div>

          <div class="flex_container">
            <div class="text_title"></div>
            <div class="caption"> ALL 이면 모든 돈방의 가상 데이터 삭제</div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import Apis from '@/api/apis'

export default {
  name: "AlertDataTest",
  data() {
    return{
      phone:'',
      rc:'',
      value:'',
      sec:'',
      rc2:'',
    }
  },
  async created(){
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
  },
  methods:{
    checkAll() {
      this.$store.commit("resMessage", "");

      Apis.check_all((res) => { 
        // console.log('hi',res);
        this.$store.commit("resMessage", res.result + " 완료 SMS Alarm Check RUN All 😀");
      }).catch( (err)=>{
        console.log("check_all 호출 오류",err)
      });
    },
    checkNoData(){
      Apis.check_no_data((res) => { 
        this.$store.commit("resMessage", res.result + " 완료 No Data RUN 😁");
      }).catch( (err)=>{
        console.log("check_no_data 호출 오류",err)
      });
    },
    checkDiff(){
      Apis.check_diff((res) => { 
        // console.log('hi2',res);
        this.$store.commit("resMessage", res.result + " 완료 Diff RUN 😏");
      }).catch( (err)=>{
        console.log("check_diff 호출 오류",err)
      });
    },
    checkRange(){
      Apis.check_range((res) => { 
        // console.log('hi3',res);
        this.$store.commit("resMessage", res.result + "완료 Range RUN 🙃");
      }).catch( (err)=>{
        console.log("check_range 호출 오류",err)
      });
    },
    smsWait(){
      Apis.clear_sms_wait(this.phone, (res) => {
        // console.log(res);
        this.$store.commit("resMessage", "수신 대기 해제 완료");

        this.phone = '';
      }).catch( (err)=>{
        console.log("clear_sms_wait 호출 오류",err)
      });
    },
    setData(){
      Apis.set_test_data(this.rc, this.value, this.sec, (res) => {
        // console.log(res);
        this.$store.commit("resMessage", "가상 데이터 업데이트 완료");

        this.rc = '';
        this.value = '';
        this.sec = '';
      }).catch( (err)=>{
        console.log("set_test_data 호출 오류",err)
      });
    },
    deleteData(){
      Apis.clear_test_data(this.rc2, (res) => {
        // console.log(res);
        this.$store.commit("resMessage", "가상 돈방 데이터 삭제 완료");
        this.rc2 = '';
      }).catch( (err)=>{
        console.log("clear_test_data 호출 오류",err)
      });
    }

  }
}
</script>

<style scoped>
.flex_container{
  display:flex;
  align-items: center;
}
.text_title{
  width:200px;
  font-weight:600;
}
.text_subtitle{
  width:100px;
}
.v-input{
  max-width:130px !important;
}
.option_btn{
  margin-left:10px !important;
}
.section{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin-top:10px;
  padding:20px;
  border-radius: 20px;
}
</style>